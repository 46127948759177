@import '../../../../../../estee_base/scss/theme-bootstrap';
@import '../../../../scss/sections/variable';

.custom-collection-sort-v1 {
  margin: 0 0 20px;
  padding: 0;
  text-transform: uppercase;
  @media (min-width: $tablet-width-min) {
    float: right;
    margin: 0;
    min-width: 175px;
    padding: 0;
    text-align: right;
  }
  .mpp-sort__menu {
    color: #{$color-navy};
    font-size: 0.75rem;
    height: 48px;
    line-height: 4;
    width: 100%;
  }
  .selectBox {
    border: 1px solid #{$color-navy};
    display: block;
    @media (min-width: $tablet-width-min) {
      border-color: transparent;
    }
  }
  .selectBox-menuShowing {
    border-color: $color-navy;
  }
  .selectBox-menuShowing-bottom {
    border-bottom-color: transparent;
  }
  .selectBox-menuShowing-top {
    border-top-color: transparent;
  }
  .selectBox-label {
    display: block;
    padding: 0 20px 0 10px;
    width: 100%;
    @media (min-width: $tablet-width-min) {
      text-align: right;
    }
  }
}

.section-build-gift {
  .selectBox-dropdown-menu {
    font-size: 0.75rem;
    line-height: 1.25;
    text-transform: uppercase;
  }
}
